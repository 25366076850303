import { createAction, props } from '@ngrx/store';

import { RelatedUser } from '../../models';

export const loadRequest = createAction('[Related Users] Load Request');

export const loadSuccess = createAction(
  '[Related Users] Load Success',
  props<{ items: RelatedUser[] }>(),
);

export const loadFailure = createAction(
  '[Related Users] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Related Users] Reset State');
